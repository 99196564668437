.navbarStyle {
    background-color: #374F63;

}

.TypeStyle {
    color: white;
    text-decoration: none;
    
}

.TypeStyle:hover {
    color: white;
}
div[id="UeberzeitenPaper"] {
    max-height: 236px;
    padding: 7px;
    overflow-y: scroll
}

table {
    width: 100%;
}

table[id="daysWithOvertime"] {
    height: 75px;
    overflow: auto;
}

tr[class="overtimeDayItem"] { 
    width: 100%;
}

tbody[class="daysWithOvertimeBody"] {
    width: 100%;
}

td[class="label"] {
    width: 50%;
    overflow-x:visible;
}

td[class="value"] {
    width: 50%;
}

div[id="spacerOvertime"] {
    height: 7px;
}

div[id="overtimeDays"] {
    max-height: 10%;
}
div[id="KundenoptionsPaper"] {
    width: 100%;
    height: 100%;
    padding: 15px;
}

div[id="MitarbeiterLoginPaper"] {
    width: 100%;
    height: 100%;
    padding: 15px;
}

div[id="MitarbeiterLogin"] {
    display: flex;
}

div[id="KundenAzureLogin"] {
    display: flex;
}

input[class="CodeInput"] {
    color: #d4ea52 !important;
    margin-right: 5px;
}

button[className="LogInLogOut"] {
    color: #d4ea52 !important;
}

div[id="LogoContainer"] {
    top: 15%;

    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: block;
}

div[id="AnwesenheitsButtonsPaper"] {
    height: 236px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 7px;
    justify-content: center;
}

div[id="ToDoHeader"] {
    align-items: center;
    display: flex;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
}

div[id="ToDoListPaper"] {
    width: 100%;
    height: 236px;
    overflow-y: auto;
    overflow-x: hidden;

    padding-right: 4px;
}

tr[class="ToDoListItem"] {
    border-top: 1px solid black;
    margin-top: 1px;
}

td[class="openToDoIcon"] {
    width: 20px;
}

td[class="stateIcon"] {
    width: 20px;
}

td[class="Title"] {
    overflow-x: hidden;
}

img[class="OpenIn"] {
    cursor: pointer;
    width: 20px;
}

table[id="ToDoTable"] {
    margin-left: 7px;

    width: 100%;
    max-height: 100%;
}


img[class="OpenInIMG"] {
    width: 20px;
}

td[class="OpenInTD"] {
    width: 20px;
}

td[class="VergangeneZeit"] {
    padding-right: 2px;
    text-align: right;
}

tr[class="AnwesenheitsdetailItem"] {
    width: 100%;
    height: 25px;

    border-bottom: solid black 1px;
}

div[id="AnwesenheitsdetailsPaper"] {
    height: 350px;

    padding: 7px;
    overflow: auto;
}

div[id="FakturaGraph"] {
    border: #bdbab7 2px solid;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    height: 350px;
}

div[id="ZeiteintraegeGraph"] {
    padding: 7px;

    height: 350px;
}

div[id="ZeiteintraegeGraphPaper"] {
    height: 350px;
}

td[class="Beschreibung"] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 225px;
}

td[class="Kunde"] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75px;
}

tr[class="TimeItem"] > td {
    padding-right: 3px;
}

tr[class="TimeItem"] {
    border-bottom: black 1px solid;
}

tr[class="THEADZTW"] {
    border-bottom: black 2px solid; 
}

td[class="AddNewZV"] {
    width: 50px;
    padding-right: 3px;
}

td[class="OpenInZV"] {
    width: fit-content;
    padding-right: 3px;
}

div[id="ZeiteintraegeViewPaper"] {
    overflow-x: auto;
    height: 350px;
    padding: 7px;
}

div[id="ZeiteintraegeView"] {
    overflow-x: auto;
    max-height: 100%;
}

canvas[id="FakturaGraph"] {
    width: 100% !important;
    height: 85% !important;
}

div[id="ZeiteintraegeKundeGraph"] {
    padding: 7px;
}

div[id="ZeiteintraegeKundeGraphPaper"] {

    height: 350px;

    padding: 7px;
}

div[id="AnwesenheitsViewPaper"] {
    padding: 7px;
    height: 100%
}

div[id="row3"] {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    display:flex;
    justify-content: center;
}

div[class="ButtonRow"] {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 7px;
}

p[id="or"] {
    padding-top: 5px;
    color: #d4ea52 !important;
    font-family: 'din-2014',sans-serif;
    text-align: center;
}

h2 {
    color: #d4ea52 !important;
    font-family: 'din-2014',sans-serif;
}
div[id="WrapperFormMain"] {
    display: flex;
    padding-top: 15px;
    width: 100%;
    height: calc(100% - 44px - 75px);
    justify-content: center;
}

div[id="QuickActionsRow"] {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

div[id="ZeiteintragPaper"] {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    min-height: 25%;
}

div[id="ZeitdetailsPaper"] {
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
    min-height: 25%;
}

div[id="DatumUndDauerPaper"] {
    width: 100%;
    height: fit-content;
    min-height: 25%;
    padding-bottom: 15px;
}


div[id="col2"] {
    width: 30%;
    padding-right: 15px;
}

div[id="ZuordnungPaper"] {
    width: 100%;
    height: fit-content;;
    min-height: 25%;
    padding-bottom: 15px;
}

div[id="BudgetArbeitspaketPaper"] {
    width: 100%;
    height: fit-content;
    min-height: 25%;
    padding-bottom: 15px;
}

div[id="col3"] {
    width: 30%;
    padding-left: 15px;
}

div[id="ZeiteintragTableWrapper"] {
    margin-left: 15px;
    margin-right: 15px;
}
div[id="ZeitdetailsTableWrapper"] {
    margin-left: 15px;
    margin-right: 15px;
}

div[id="DNDTableWrapper"] {
    margin-left: 15px;
    margin-right: 15px;
}

h5 {
    padding-left: 7px;
    padding-top: 7px;
    padding-bottom: 10px;
}

th[id="label"] {
    width: 33%;
    padding: 0px;
}

th[id="value"] {
    height: 0px;
    padding: 0px;
}

td[class="value"] {
    padding: 0px;
}

input[id="TaetigkeitSelector"] {
    height: 50px;
}

input[id="KundeSelector"] {
    height: 50px;
}

input[id="ProjektSelector"] {
    height: 50px;
}

input[id="ArbeitspaketSelector"] {
    height: 50px;
}

input[id="nms_stundenlohn"] {
    height: 50px;
}

input[id='Text-Display'] {
    height: 50px;
}

div[id="BudgetArbeitspaketWrapper"] {
    margin-left: 15px;
    margin-right: 15px;
}